import api from "@/services/external/api-service";

const basePath = "paypal";

export const getPlansByRole = () => api().get(`${basePath}/plans-by-role`);

export const validateToSubscribe = () =>
  api().get(`${basePath}/subscriptions/validate-to-subscribe`);

export const createConfirmedSubscription = (subscriptionId) =>
  api().post(`${basePath}/subscriptions/${subscriptionId}`);

export const patchSubscriptionPlan = (newPaypalPlanId) =>
  api().patch(`${basePath}/subscriptions/${newPaypalPlanId}`);

export const sendError = (error) => api().post(`${basePath}/error`, error);

export const getCurrentSubscriptionData = () =>
  api().get(`${basePath}/subscriptions/current`);

export const getSubscriptionsHistory = () =>
  api().get(`${basePath}/subscriptions/history`);

export const cancelSubscription = ({ reason }) =>
  api().post(`${basePath}/subscriptions/cancel`, { reason });

export const createStripeSession = (planId) =>
  api().post(`${basePath}/subscriptions/stripe/${planId}`);

export const renewLoggedUser = () =>
  api().get(`${basePath}/subscriptions/renew-user-logged`);
