<template>
  <new-perseu-card
    title="Confirmação de assinatura"
    render-back-button
    @click-back="$router.go(-1)"
  >
    <v-row justify="center">
      <template v-if="currentSubscriptionData.price"> </template>
      <v-col lg="6" sm="8" cols="12">
        <plan-card-view
          :plan="selectedPlan"
          :old-price="currentSubscriptionData.price"
          :old-plan-name="currentSubscriptionData.planName"
        />
      </v-col>
    </v-row>
    <div class="mt-5 body-1">
      <h3 class="title mb-2">
        <v-icon>integration_instructions</v-icon>
        Instruções
      </h3>
      <p class="body-1">
        Neste momento não faremos <u>nenhuma cobrança adicional</u>, somente na
        próxima data de pagamento o novo valor será cobrado. <br />
      </p>
      <p>
        Ao clicar no botão abaixo, abrirá uma nova janela do PayPal para
        confirmação do novo valor de cobrança. <br />
      </p>
      <p>Você só poderá alterar o seu plano novamente em 30 dias. <br /></p>
      <p>
        <strong>
          Atenção: Enviaremos um e-mail confirmando a troca de plano. Siga as
          instruções contidas no conteúdo do e-mail.
        </strong>
      </p>
    </div>
    <div class="text-center mt-8">
      <v-btn
        color="secondary"
        x-large
        rounded
        elevation="0"
        @click="confirmChange"
      >
        Confirmar alteração no Paypal
      </v-btn>
    </div>
  </new-perseu-card>
</template>

<script>
import {
  patchSubscriptionPlan,
  sendError,
  getCurrentSubscriptionData,
} from "@/services/external/paypal-service";

export default {
  name: "Confirmation",
  components: {
    PlanCardView: () =>
      import("@/domains/subscriptions/presentation/components/PlanCardView"),
  },
  data: () => ({
    currentSubscriptionData: {},
  }),
  computed: {
    selectedPlan() {
      return this.$store.state.subscriptions.selectedPlan;
    },
  },
  mounted() {
    if (!this.selectedPlan.idPaypal) {
      this.$router.push({ name: "subscriptions.plans" });
    }
    this.fillCurrentSubscriptionData();
  },
  methods: {
    async confirmChange() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await patchSubscriptionPlan(
          this.selectedPlan.idPaypal
        );
        window.open(data.approveUrl, "_blank");
        this.$infoAlert({
          message:
            "Sua alteração de plano foi solicitada. " +
            "Enviaremos um e-mail assim que recebermos a confirmação do PayPal. " +
            "Siga as instruções contidas no corpo do e-mail.",
        });
      } catch (error) {
        await sendError(error.stack);
        this.$errorHandler(error, true);
      } finally {
        this.$router.push({ name: "home" });
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async paypalError(error) {
      await sendError(error.stack ? error.stack : error);
      this.$errorAlert({
        title: "Erro ao realizar pagamento",
        message:
          "O pagamento não foi realizado. Tente novamente mais tarde. Já reportamos o erro para que seja solucionado o mais breve possível.",
      });
      this.$router.push({ name: "subscriptions.plans" });
    },
    paypalCancelled() {
      this.$warningAlert({
        message: "Você cancelou o pagamento do plano. Pagamento não realizado.",
      });
    },
    async fillCurrentSubscriptionData() {
      const { data } = await getCurrentSubscriptionData();
      this.currentSubscriptionData = data;
    },
  },
};
</script>

<style scoped></style>
